import { Controller } from "stimulus"
import { once } from '../utils'

export default class extends Controller {
  static targets = [ "modal" ]

  connect() {
    this.element.classList.add("modal--show-animation")
  }

  close() {
    once(this.element, 'animationend', () => {
      this.element.remove()
    })
    this.element.classList.add("modal--hide-animation")
    this.element.classList.remove("modal--show-animation")

    this.element.parentElement.removeAttribute("src")
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.close()
    }
  }

  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.close()
    }
  }

  closeBackground(e) {
    if (e && this.modalTarget.contains(e.target)) {
      return
    }
    this.close()
  }
}
