import { Autocomplete } from 'stimulus-autocomplete'

const extractTextValue = node => {
  if (node.hasAttribute("aria-labeledby")) {
    return node.getAttribute("aria-labeledby").split(" ")
      .map(id => document.getElementById(id))
      .filter(el => el !== null)
      .map(el => el.textContent.trim())
      .join(" ")
  }
  return node.textContent.trim()
}

export default class extends Autocomplete {
  commit(selected) {
    if (selected.getAttribute("aria-disabled") === "true") return

    if (selected.tagName == "A") {
      selected.click()
      this.resultsTarget.hidden = true
      return
    }

    const textValue = extractTextValue(selected)
    const value = selected.getAttribute("data-autocomplete-value") || textValue
    this.inputTarget.value = textValue

    if (this.hasHiddenTarget) {
      this.hiddenTarget.value = value
      this.hiddenTarget.dispatchEvent(new Event("input"))
      this.hiddenTarget.dispatchEvent(new Event("change"))
    } else {
      this.inputTarget.value = value
    }

    this.inputTarget.focus()
    this.hideAndRemoveOptions()

    this.element.dispatchEvent(
      new CustomEvent("autocomplete.change", {
        bubbles: true,
        detail: { value, textValue }
      })
    )
  }
}
