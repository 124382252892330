import { Controller } from "stimulus"
import { showNode, hideNode } from "../utils"

const ariaHiddenAttr = "aria-hidden"
const ariaHiddenAttrValue = "true"
const isHidden = el => el.getAttribute(ariaHiddenAttr) === ariaHiddenAttrValue
const show = el => {
  showNode(el)
  el.removeAttribute(ariaHiddenAttr)
}
const hide = el => {
  hideNode(el)
  el.setAttribute(ariaHiddenAttr, ariaHiddenAttrValue)
}

export default class extends Controller {
  static targets = [ "input", "item", "section", "empty" ]

  connect() {
    this.inputTarget.addEventListener("keydown", this.onKeyDown, false)
    this.inputTarget.addEventListener("change", this.onChange, false)
    this.inputTarget.addEventListener("input", this.onChange, false)

    if (this.hasEmptyTarget) {
      hide(this.emptyTarget)
    }
  }

  onChange = e => {
    if (typeof this._timeout === "number") {
      clearTimeout(this._timeout)
    }

    const term = e.target.value
    this._timeout = setTimeout(() => this._filterItems(term), 300)
  }

  onKeyDown = e => {
    if (e.key == "Escape") {
      this.reset()
    }
  }

  reset() {
    this.inputTarget.value = null
    this.sectionTargets.forEach(el => showNode(el))
    this.itemTargets.forEach(el => showNode(el))
    hideNode(this.emptyTarget)
  }

  _filterItems(term) {
    const re = new RegExp(term, "i")

    this.itemTargets.forEach(el => {
      el.textContent.match(re) ? show(el) : hide(el)
    })

    this._hideEmptySections()
    this._showEmpty()
  }

  _hideEmptySections() {
    if (!this.hasSectionTarget) { return }

    const selector = `[data-${this.identifier}-target="item"]`
    this.sectionTargets.forEach(el => {
      const items = Array.from(el.querySelectorAll(selector))
      items.every(isHidden) ? hide(el) : show(el)
    })
  }

  _showEmpty() {
    if (this.itemTargets.every(isHidden)) {
      showNode(this.emptyTarget)
    } else {
      hideNode(this.emptyTarget)
    }
  }
}
